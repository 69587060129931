import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from '../store';
import i18n from '../i18n';
import AnalyticsProvider from '../components/analytics/AnalyticsProvider';
import SearchProvider from '../components/search/SearchProvider';
import SearchHeadband from '../components/search/SearchHeadband';
import HomepageCoverSlider from '../components/homepage/HomepageCoverSlider';
import HomepageNewest from '../components/homepage/HomepageNewest';
import HomepageWhy from '../components/homepage/HomepageWhy';
import HomepageOldWhy from '../components/homepage/HomepageOldWhy';
import SponsorLink from '../components/home/SponsorLink';
import Api from '../api/Api';
import Utils from '../utils/utils';
import { isOlympicGamesPushFlagActive } from '../utils/features';
import HomeGreenBlock from '../components/dashboard/HomeGreenBlock';
import Inspirational from '../components/dashboard/Inspirational';
import HowItWorks from '../components/dashboard/how-it-works/HowItWorks';
import HomeNewest from '../components/dashboard/HomeNewest';
import DestinationsCarousel from '../components/dashboard/DestinationCarousel';
import SearchBlock from '../components/dashboard/SearchBlock';
import { SearchBarContext } from '../components/search/SearchBar';
import OlympicGames from '../components/dashboard/OlympicGames';

let isLogged = null;

const layoutLogin = document.getElementsByClassName('layout-login');
if (layoutLogin) {
    isLogged = layoutLogin.length > 0;
}

// render search headband
const searchHeadband = document.getElementById('search-headband');
if (searchHeadband) {
    createRoot(searchHeadband).render(
        <Provider store={store}>
            <SearchHeadband />
        </Provider>
    );
}

// render search offline header
const searchOfflineHeader = document.getElementById('search-offline-header');
if (searchOfflineHeader) {
    createRoot(searchOfflineHeader).render(
        <Provider store={store}>
            <SearchHeadband inHeader={true} />
        </Provider>
    );
}

const homepageSearch = document.getElementById('home-search');
if (homepageSearch) {
    const SearchBlockWithLocalLogic = () => {
        useEffect(() => {
            const searchBar = document.querySelector(
                '#head-nav-band .has-reduced-searchbar .navbar-right .search-bar-wrapper'
            );
            const topbarPricingPage = document.querySelector('#topbar-pricing-page a');
            const howItWorks = document.querySelector('.how-it-works a');
            const navbar = document.querySelector('.navbar');
            const homepageSearchBlock = document.querySelector('.home-picture');
            const signin = document.getElementById('signin');
            const navbarToggleCollapsed = document.querySelector('.navbar-toggle');
            const iconsBar = document.querySelectorAll('.navbar-toggle .icon-bar');
            const header = document.getElementById('head-nav-band');
            const bCorpInfo = document.getElementsByClassName('b-corp-info').length >= 1;

            if (bCorpInfo) {
                topbarPricingPage.style.color = 'black';
                howItWorks.style.color = 'black';
                signin.style.color = 'black';
            }

            const changeIconsBarColor = (color) => {
                iconsBar.forEach((icon) => {
                    icon.style.backgroundColor = color;
                });
            };

            $('body').addClass('optimize-homepage-search');

            // old homepage
            $('#search-offline-header').addClass('hidden');
            $('.classic-homepage-title').addClass('hidden');
            $('.classic-homepage-text').addClass('hidden');
            $('.homepage-pictures').addClass('hidden');

            // new homepage
            $('.homepage-title').removeClass('hidden');
            $('.new-home-info').removeClass('hidden');

            document.addEventListener('scroll', () => {
                const position = homepageSearchBlock.getBoundingClientRect();
                const switchColorOnScroll = window.pageYOffset < header.offsetTop && bCorpInfo;

                if (position.top < window.innerHeight && position.bottom >= 0) {
                    searchBar.classList.add('not-display');
                    navbar.style.backgroundColor = 'transparent';
                    topbarPricingPage.style.color = switchColorOnScroll ? 'black' : 'white';
                    howItWorks.style.color = switchColorOnScroll ? 'black' : 'white';
                    signin.style.color = switchColorOnScroll ? 'black' : 'white';
                    navbarToggleCollapsed.style.border = 'none';
                    changeIconsBarColor('#fff');
                } else {
                    searchBar.classList.remove('not-display');
                    navbar.style.backgroundColor = 'white';
                    topbarPricingPage.style.color = 'black';
                    howItWorks.style.color = 'black';
                    signin.style.color = 'black';
                    navbarToggleCollapsed.style.border = '1px solid #ddd';
                    changeIconsBarColor('#888');
                }
            });
        }, []);

        return (
            <SearchBlock
                className="homepage-search"
                context={SearchBarContext.HOMEPAGE}
                renderSearchInformation={() => (
                    <p className="homepage-search-label home-page-block-small-title">
                        {homepageSearch.dataset.isFromUs
                            ? i18n.t('dashboard:homepage-search.label-us')
                            : i18n.t('dashboard:homepage-search.label')}
                    </p>
                )}
            />
        );
    };

    createRoot(homepageSearch).render(
        <Provider store={store}>
            <Router>
                <AnalyticsProvider eventsApiOptions={{ timeout: 1000 }}>
                    <SearchProvider>
                        <SearchBlockWithLocalLogic />
                    </SearchProvider>
                </AnalyticsProvider>
            </Router>
        </Provider>
    );
}

const homeGreenBlock = document.getElementById('home-green-block');
if (homeGreenBlock) {
    createRoot(homeGreenBlock).render(<HomeGreenBlock />);
}

const homeInspirational = document.getElementById('home-inspirational');
if (homeInspirational) {
    createRoot(homeInspirational).render(<Inspirational />);
}

const homepageHowItWorks = document.getElementById('how-it-works');
if (homepageHowItWorks) {
    createRoot(homepageHowItWorks).render(
        <Provider store={store}>
            <HowItWorks />
        </Provider>
    );
}

const homepageHomeWhy = document.getElementById('home-why');
if (homepageHomeWhy) {
    createRoot(homepageHomeWhy).render(
        <Provider store={store}>
            <HomepageWhy />
        </Provider>
    );
}

const homepageHomeOldWhy = document.getElementById('home-old-why');
const showYourSelf = i18n.language !== 'fr' && i18n.language !== 'es';
if (homepageHomeOldWhy && showYourSelf) {
    createRoot(homepageHomeOldWhy).render(<HomepageOldWhy />);
}

if (isOlympicGamesPushFlagActive()) {
    const homeOlympic = document.getElementById('home-olympic');
    if (homeOlympic) {
        createRoot(homeOlympic).render(
            <Provider store={store}>
                <OlympicGames />
            </Provider>
        );
    }
}

const homeNewest = document.getElementById('home-newest');
if (homeNewest) {
    createRoot(homeNewest).render(
        <Provider store={store}>
            <HomeNewest isLogged={isLogged} />
        </Provider>
    );
}

const homepageDestinations = document.getElementById('home-destinations');
const authLangForNow = ['fr', 'en', 'es'];
const foundLang = authLangForNow.find((lang) => lang === i18n.language);
if (homepageDestinations && foundLang) {
    const destinations = require(`../json/destinations/homepage/${i18n.language}.json`);
    const title = i18n.t('home:destinations.title');
    const subtitle = i18n.t('home:destinations.subtitle');
    createRoot(homepageDestinations).render(
        <DestinationsCarousel destinations={destinations} title={title} subtitle={subtitle} hasText={true} />
    );
}

const homepageNewest = document.getElementById('homepage-newest');
if (homepageNewest) {
    createRoot(homepageNewest).render(
        <Provider store={store}>
            <HomepageNewest isLogged={isLogged} isSimilarHome={false} />
        </Provider>
    );
}

const homepageCoverSlider = document.getElementById('cover-slider-container');
if (homepageCoverSlider) {
    createRoot(homepageCoverSlider).render(<HomepageCoverSlider />);
}

// render sponsor signup section when user come from sponsor link
const sponsorLink = document.getElementById('sponsor-link');
if (sponsorLink) {
    const coverSlider = document.getElementById('cover-slider-container');
    const signup = document.getElementById('signup');
    const sponsorKey = Utils.getParameter('sponsorkey', null);

    Api.Sponsor.getGodfatherBySponsorKey(sponsorKey)
        .then((godfather) => {
            signup.innerHTML = i18n.t('home:homepage.sponsor-link.button');
            coverSlider.classList.add('blur');

            if (!godfather) {
                document.location.assign('/');
            }

            createRoot(sponsorLink).render(<SponsorLink godfather={godfather} />);
        })
        .catch(() => {
            document.location.assign('/');
        });
}

$(document).ready(() => {
    // shim layer with setTimeout fallback
    window.requestAnimationFrame = (function () {
        return (
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            function (callback) {
                window.setTimeout(callback, 1000 / 60);
            }
        );
    })();
});
